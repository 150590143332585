import { fetchAlertsAsync } from 'src/actions/alerts';
import { selectAlertsNeedData } from 'src/selectors/alerts';
import {
  loadCurrentOrgAsync,
  loadCurrentPatientAsync,
  loadCurrentUserAsync,
  loadCurrentUserOrganizationsAsync,
  renewTokenAsync,
} from '../actions/auth';
import { loadCurrentSubscriptionsAsync } from '../actions/currentSubscriptions';
import {
  loadDraftFolderAsync,
  loadInboxFolderAsync,
  loadSentFolderAsync,
  loadSharedFolderAsync,
  loadTrashFolderAsync,
} from '../actions/folders';
import { loadThreadAsync } from '../actions/threads';
import {
  selectCurrentPatientNeedData,
  selectCurrentUserNeedData,
  selectCurrentUserOrganizationsNeedData,
  selectTokenRenewalNeeded,
} from '../selectors/auth';

import { selectCurrentOrgNeedData } from '../selectors/currentOrg';
import { selectCurrentSubscriptionsNeedData } from '../selectors/currentSubscriptions';
import {
  selectDraftFolderNeedData,
  selectInboxFolderNeedData,
  selectSentFolderNeedData,
  selectSharedFolderNeedData,
  selectTrashFolderNeedData,
} from '../selectors/folders';
import { selectThreadsNeedData } from '../selectors/threads';

export { default as reducer } from '../reducers';

export const effects = [
  {
    selector: selectCurrentUserOrganizationsNeedData,
    actionCreator: loadCurrentUserOrganizationsAsync,
  },
  {
    selector: selectCurrentUserNeedData,
    actionCreator: loadCurrentUserAsync,
  },
  {
    selector: selectCurrentPatientNeedData,
    actionCreator: loadCurrentPatientAsync,
  },
  {
    selector: selectTokenRenewalNeeded,
    actionCreator: renewTokenAsync,
  },
  {
    selector: selectCurrentOrgNeedData,
    actionCreator: loadCurrentOrgAsync,
  },
  {
    selector: selectCurrentSubscriptionsNeedData,
    actionCreator: loadCurrentSubscriptionsAsync,
  },
  {
    selector: selectInboxFolderNeedData,
    actionCreator: loadInboxFolderAsync,
  },
  {
    selector: selectSharedFolderNeedData,
    actionCreator: loadSharedFolderAsync,
  },
  {
    selector: selectSentFolderNeedData,
    actionCreator: loadSentFolderAsync,
  },
  {
    selector: selectDraftFolderNeedData,
    actionCreator: loadDraftFolderAsync,
  },
  {
    selector: selectTrashFolderNeedData,
    actionCreator: loadTrashFolderAsync,
  },
  {
    selector: selectThreadsNeedData,
    actionCreator: loadThreadAsync,
  },
  {
    selector: selectAlertsNeedData,
    actionCreator: fetchAlertsAsync,
  },
];
