import hash from 'object-hash';
import {
  format,
  differenceInDays,
  differenceInYears,
  fromUnixTime,
  addMinutes,
} from 'date-fns';

class GeneralUtilities {
  static isIframe() {
    try {
      if (window.self !== window.top) {
        return true;
      }
    } catch (e) {
      // do nothing
    }
    return false;
  }

  static validateEmail(email) {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return email.match(re);
  }

  static validatePhone(phone) {
    const re =
      /^\+{0,2}([-. ])?(\(?\d{0,3}\))?([-. ])?\(?\d{0,3}\)?([-. ])?\d{3}([-. ])?\d{4}/;
    return phone.match(re);
  }

  static getCommonName(user) {
    return GeneralUtilities.getDisplayNameString(user, user.displayNamePattern);
  }

  static getDisplayNameSender(user) {
    if (user.messageAccountType === 'PERSONAL') {
      return user.commonName;
    }
    return [user.orgName, ' (', user.commonName, ')'].join('');
  }

  static getDisplayNameRecipient(user) {
    if (user.messageAccountType === 'PERSONAL') {
      return user.commonName;
    }
    return user.orgName;
  }

  static getDisplayNameString(profileData, type) {
    switch (type) {
      case 'GENERIC_1':
        return [
          this.getCourtesyDisplayString(profileData.courtesy),
          ' ',
          profileData.firstName,
          ' ',
          profileData.lastName,
        ].join('');
      case 'GENERIC_2':
        return [
          this.getCourtesyDisplayString(profileData.courtesy),
          ' ',
          profileData.lastName,
          ', ',
          profileData.firstName,
        ].join('');
      default:
        return [
          this.getCourtesyDisplayString(profileData.courtesy),
          ' ',
          profileData.firstName,
          ' ',
          profileData.lastName,
        ].join('');
    }
  }

  static getCourtesyDisplayString(courtesy) {
    if (courtesy) {
      return `${courtesy[0].toUpperCase()}${courtesy
        .substring(1)
        .toLowerCase()}.`;
    }
    return '';
  }

  static getFirstLetter(name) {
    const firstLetter = name
      .replace(
        /^(mr[\s|.]|ms[\s|.]|mrs[\s|.]|miss[\s|.]|dr[\s|.]|prof[\s|.])[\s|.]*/i,
        '',
      )
      .substring(0, 1)
      .toUpperCase();
    return firstLetter;
  }

  static titleCase(str) {
    if (!str || typeof str !== 'string') {
      return '';
    }
    return str
      .toLowerCase()
      .split(' ')
      .map((word) => `${word.charAt(0).toUpperCase()}${word.slice(1)}`)
      .join(' ');
  }

  static camelToTitle = (camelCase) =>
    camelCase
      .replace(/([A-Z])/g, (match) => ` ${match}`)
      .replace(/^./, (match) => match.toUpperCase());

  static isValidPassword(password) {
    const regex = /(?=.*\d)(?=.*[A-Z]).{8,32}/;
    return !!password.match(regex);
  }

  static hasLowerCase(str) {
    return str.toUpperCase() !== str;
  }

  static hasUpperCase(str) {
    return str.toLowerCase() !== str;
  }

  static hasNumber(str) {
    return /\d/.test(str);
  }

  static hasAtleast8Characters(str) {
    return str.length >= 8;
  }

  static isEmpty(str) {
    return !str || str.trim().length === 0;
  }

  static getFormattedDateTimeString(d) {
    const date = fromUnixTime(d / 1000);
    let displayDate = format(date, 'MMM d');
    if (differenceInDays(date, new Date() === 0)) {
      displayDate = format(date, 'h:mm a');
    } else if (differenceInYears(date, new Date() !== 0)) {
      displayDate = format(date, 'MMM d, yyyy');
    }
    return displayDate;
  }

  static getChatDateTimeString(d) {
    const date = fromUnixTime(d);
    let displayDate = format(date, 'MMM d h:mm a');
    if (differenceInDays(date, new Date() === 0)) {
      displayDate = format(date, 'h:mm a');
    } else if (differenceInYears(date, new Date() !== 0)) {
      displayDate = format(date, 'MMM d, yyyy h:mm a');
    }
    return displayDate;
  }

  static getLongFormattedDateTimeString(d, originalFormatString) {
    const date = fromUnixTime(d / 1000);
    let displayDate = format(date, 'MMM d yyyy, hh:mm a');
    return displayDate;
  }

  static formattedSizeLabel(sizeInBytes) {
    if (sizeInBytes > 1000000) {
      return [(sizeInBytes / 1000000).toFixed(1), 'MB'].join('');
    } else if (sizeInBytes > 1000) {
      return [(sizeInBytes / 1000).toFixed(1), 'KB'].join('');
    }
    return [sizeInBytes, 'B'].join('');
  }

  static hashNameIntoColor(id) {
    const hashed = hash(id);
    const maxRange = 360;

    // taking first 3 hex digits and converting to number
    const hValue = parseInt(hashed.substr(0, 3), 16);

    // hue values between 0 - 360
    const h = Math.round((hValue / 0xfff) * maxRange);
    const sValue = parseInt(hashed.substr(4, 3), 16);

    // saturation between 70- 90%
    const s = Math.round((sValue / 0xfff) * 20) + 70;
    const lValue = parseInt(hashed.substr(7, 3), 16);

    // lightness between 50- 60%
    const l = Math.round((lValue / 0xfff) * 10) + 50;

    return `hsla(${h}, ${s}%, ${l}%, 1)`;
  }

  static encode(str) {
    return encodeURIComponent(str).replace(
      /[!'()*]/g,
      (x) => `%${x.charCodeAt(0).toString(16).toUpperCase()}`,
    );
  }

  static createOrganizationTypeOptions = (organizationTypes) => {
    const organizationTypeOptions = [];
    if (organizationTypes) {
      organizationTypes.forEach((organizationType) => {
        organizationTypeOptions.push({
          label: organizationType.label,
          value: organizationType.value,
        });
      });
    }
    return organizationTypeOptions.sort((a, b) =>
      a.label.localeCompare(b.label),
    );
  };

  static createOrganizationSubTypeOptions = (organizationSubTypes) => {
    const organizationSubTypeOptions = [];
    if (organizationSubTypes) {
      organizationSubTypes.forEach((organizationSubType) => {
        organizationSubTypeOptions.push({
          label: organizationSubType.label,
          value: organizationSubType.value,
        });
      });
    }
    organizationSubTypeOptions.sort((a, b) => a.label.localeCompare(b.label));
    organizationSubTypeOptions.splice(
      organizationSubTypeOptions.findIndex(
        (element) => element.value === 'OTHER',
      ),
      1,
    );
    if (organizationSubTypeOptions.length !== 0) {
      organizationSubTypeOptions.push({ label: 'Other', value: 'OTHER' });
    }
    return organizationSubTypeOptions;
  };

  static getShortProvinceName(longName) {
    if (!longName) {
      return '';
    }
    return this.provincesAndStates().find((p) => p.label === longName)?.value;
  }
  static provincesAndStates() {
    return [
      { value: 'AB', label: 'Alberta' },
      { value: 'BC', label: 'British Columbia' },
      { value: 'MB', label: 'Manitoba' },
      { value: 'NB', label: 'New Brunswick' },
      { value: 'NL', label: 'Newfoundland and Labrador' },
      { value: 'NT', label: 'Northwest Territories' },
      { value: 'NS', label: 'Nova Scotia' },
      { value: 'NU', label: 'Nunavut' },
      { value: 'ON', label: 'Ontario' },
      { value: 'PE', label: 'Prince Edward Island' },
      { value: 'QC', label: 'Quebec' },
      { value: 'SK', label: 'Saskatchewan' },
      { value: 'YT', label: 'Yukon' },
      { value: 'AL', label: 'Alabama' },
      { value: 'AK', label: 'Alaska' },
      { value: 'AS', label: 'American Samoa' },
      { value: 'AZ', label: 'Arizona' },
      { value: 'AR', label: 'Arkansas' },
      { value: 'CA', label: 'California' },
      { value: 'CO', label: 'Colorado' },
      { value: 'CT', label: 'Connecticut' },
      { value: 'DE', label: 'Delaware' },
      { value: 'DC', label: 'District of Columbia' },
      { value: 'FM', label: 'Federated States of Micronesia' },
      { value: 'FL', label: 'Florida' },
      { value: 'GA', label: 'Georgia' },
      { value: 'GU', label: 'Guam' },
      { value: 'HI', label: 'Hawaii' },
      { value: 'ID', label: 'Idaho' },
      { value: 'IL', label: 'Illinois' },
      { value: 'IN', label: 'Indiana' },
      { value: 'IA', label: 'Iowa' },
      { value: 'KS', label: 'Kansas' },
      { value: 'KY', label: 'Kentucky' },
      { value: 'LA', label: 'Louisiana' },
      { value: 'ME', label: 'Maine' },
      { value: 'MH', label: 'Marshall Islands' },
      { value: 'MD', label: 'Maryland' },
      { value: 'MA', label: 'Massachusetts' },
      { value: 'MI', label: 'Michigan' },
      { value: 'MN', label: 'Minnesota' },
      { value: 'MS', label: 'Mississippi' },
      { value: 'MO', label: 'Missouri' },
      { value: 'MT', label: 'Montana' },
      { value: 'NE', label: 'Nebraska' },
      { value: 'NV', label: 'Nevada' },
      { value: 'NH', label: 'New Hampshire' },
      { value: 'NJ', label: 'New Jersey' },
      { value: 'NM', label: 'New Mexico' },
      { value: 'NY', label: 'New York' },
      { value: 'NC', label: 'North Carolina' },
      { value: 'ND', label: 'North Dakota' },
      { value: 'MP', label: 'Northern Mariana Islands' },
      { value: 'OH', label: 'Ohio' },
      { value: 'OK', label: 'Oklahoma' },
      { value: 'OR', label: 'Oregon' },
      { value: 'PW', label: 'Palau' },
      { value: 'PA', label: 'Pennsylvania' },
      { value: 'PR', label: 'Puerto Rico' },
      { value: 'RI', label: 'Rhode Island' },
      { value: 'SC', label: 'South Carolina' },
      { value: 'SD', label: 'South Dakota' },
      { value: 'TN', label: 'Tennessee' },
      { value: 'TX', label: 'Texas' },
      { value: 'UT', label: 'Utah' },
      { value: 'VT', label: 'Vermont' },
      { value: 'VI', label: 'Virgin Islands' },
      { value: 'VA', label: 'Virginia' },
      { value: 'WA', label: 'Washington' },
      { value: 'WV', label: 'West Virginia' },
      { value: 'WI', label: 'Wisconsin' },
      { value: 'WY', label: 'Wyoming' },
    ];
  }
}

export const toSnakeCase = (str) => {
  return str
    .split(/(?=[A-Z])/)
    .join('_')
    .toLowerCase();
};

export const toUrlEncoded = (obj) => {
  return Object.keys(obj)
    .map(
      (k) =>
        encodeURIComponent(toSnakeCase(k)) + '=' + encodeURIComponent(obj[k]),
    )
    .join('&');
};

export const downloadUrl = (url) => {
  const a = document.createElement('a');
  document.body.appendChild(a);
  a.style = 'display: none';
  a.href = url;
  a.click();
  document.body.removeChild(a);
};

export const convertDateToUTC = (date) =>
  date && addMinutes(date, date.getTimezoneOffset());

export default GeneralUtilities;
