import React from 'react';
import { useSelector } from 'react-redux';
import styled from '@emotion/styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  selectIsPackageFromPackagesEndpoint,
  selectSelectedPackage,
} from '../../selectors/subscriptionPackages';
import {
  selectCalculatedPackage,
  selectHasError,
} from '../../selectors/newSubscriptionPackage';
import { selectNumberOfCliniciansInOrg } from '../../selectors/currentOrg';
import Price from './Price';

const Currency = styled.span`
  color: #aaa;
  font-weight: normal;
`;

const Row = styled.div`
  margin: 10px 0px 0px 0px;
  display: flex;
`;

const Left = styled.div`
  flex: 2;
  text-align: left;
`;

const Right = styled.div`
  flex: 1;
  text-align: right;
`;

const Footnote = styled.small`
  padding-left: 1;
`;

const ShoppingCart = () => {
  const isPackageEndpoint = useSelector(selectIsPackageFromPackagesEndpoint);
  const selectedPackage = useSelector(selectSelectedPackage);
  const calculatedPackage = useSelector(selectCalculatedPackage);
  const hasError = useSelector(selectHasError);
  const cliniciansCount = useSelector(selectNumberOfCliniciansInOrg);
  const bundle = isPackageEndpoint ? selectedPackage : calculatedPackage;

  if (!bundle) {
    return (
      <FontAwesomeIcon
        data-testid="spinner"
        icon={['fas', 'spinner']}
        spin
        className="spinner"
      />
    );
  }

  if (hasError) {
    return (
      <React.Fragment>
        <h5 className="text-danger" data-testid="error-message">
          This service is compliant with Canadian regulations &nbsp;and is only
          available to organizations located in Canada. &nbsp;You can still use
          &nbsp;
          <a href="/new/SM">Brightsquid Secure-Mail</a>
          &nbsp;to safely share patient information with anyone in the world.
        </h5>
      </React.Fragment>
    );
  }

  let totalLabel = 'Recurring monthly payment';
  let description;
  let additionalInfo;
  let annualCommmitment;
  let cleanCount = cliniciansCount || 1;
  if (bundle.name === 'PACKAGE_PIA_INSTALLMENTS') {
    additionalInfo = (
      <div data-testid="additionalInfo">
        †&nbsp; Continues with Privacy Services and Secure-Mail for $99.00/month
        plus taxes after the first year
      </div>
    );
  }
  let footnote = (
    <span>
      {annualCommmitment}
      {additionalInfo}
      <div>
        ** Additional clinician Secure-Mail seats are $57.49 per clinician per
        month.
      </div>
    </span>
  );
  let secondTotal;

  switch (bundle.name) {
    case 'SM':
      description = (
        <span>
          <div>
            <b>Clinic Performance Package** (30 days Free Trial)</b>
          </div>
          <div>&nbsp;</div>
          <div>
            Secure-Mail ({cleanCount} clinician(s), {cleanCount * 5} staff
            seats)
          </div>
          <div>Panel Messaging</div>
          <div>Privacy Breach Prevention Training</div>
          <div>Clinic Operations Optimization Support</div>
        </span>
      );
      break;
    case 'PACKAGE_PIA_INSTALLMENTS':
      description = (
        <span>
          <div>
            <b>PIA Package</b>
          </div>
          <div>&nbsp;</div>
          <div>
            Privacy Impact Assessment <Footnote>*</Footnote>
          </div>
          <div>Privacy Services </div>
          <div>Online privacy compliance training</div>
          <div>
            Secure-Mail ({cleanCount} clinician(s), {cleanCount * 5} staff
            seats) <Footnote>**</Footnote>
          </div>
          <div>Panel Messaging</div>
          <div>Privacy Breach Prevention Training</div>
          <div>Clinic Operations Optimization Support</div>
        </span>
      );
      totalLabel = 'Recurring monthly payment for 1st year †';
      break;
    case 'PACKAGE_PRIVACY_SERVICES':
      description = (
        <span>
          <div>
            <b>Privacy Services Package</b>
          </div>
          <div>&nbsp;</div>
          <div>
            Privacy Services <Footnote>*</Footnote>
          </div>
          <div>Online privacy compliance training</div>
          <div>
            Secure-Mail ({cleanCount} clinician(s), {cleanCount * 5} staff
            seats) <Footnote>**</Footnote>
          </div>
          <div>Panel Messaging</div>
          <div>Privacy Breach Prevention Training</div>
          <div>Clinic Operations Optimization Support</div>
        </span>
      );
      break;

    case 'PIA_MEDICAL':
    case 'PIA_DENTAL':
      description = (
        <span>
          <div>
            <b>Privacy Impact Assessment</b>
          </div>
        </span>
      );
      footnote = <span />;
      totalLabel = 'Added to your subscription for the next 12 months';
      break;

    default:
      description = <span>{bundle.description}</span>;
  }

  let discount;

  if (bundle.discount && bundle.discount > 0) {
    discount = (
      <b>
        <Row>
          <Left>Discount ({bundle.code})</Left>
          <Right>
            <Price value={bundle.discount} />
          </Right>
        </Row>
      </b>
    );
  }

  return (
    <React.Fragment>
      <h3>New Subscription Package</h3>
      <Row>
        <Left>{description}</Left>
        <Right>
          <Price value={bundle.subTotal} />
        </Right>
      </Row>
      <hr />
      <Row>
        <Left>Subtotal</Left>
        <Right>
          <Price value={bundle.subTotal} />
        </Right>
      </Row>
      {discount}
      <Row>
        <Left>Tax</Left>
        <Right>
          <Price value={bundle.taxTotal} />
        </Right>
      </Row>
      <hr />
      <Row>
        <Left>
          <b>{totalLabel}</b>
        </Left>
        <Right>
          <Currency>{bundle.currency}</Currency>
          &nbsp;
          <b>
            <Price value={bundle.total} />
          </b>
          <br />
          <br />
        </Right>
      </Row>
      {secondTotal}
      <Row>
        <Footnote data-testid="footnote">{footnote}</Footnote>
      </Row>
    </React.Fragment>
  );
};

export default ShoppingCart;
