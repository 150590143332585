import React from 'react';
import { useSelector } from 'react-redux';
import BundleRow from './BundleRow';
import { selectCurrentOrganizationsIndex } from '../../selectors/auth';

const formsLi = (
  <li>
    <span
      style={{
        background: 'yellow',
        padding: '0px 4px',
        fontWeight: 500,
        fontSize: 12,
      }}
    >
      NEW
    </span>{' '}
    Send secure online forms. Receive submitted information as Secure-Mail
    attachment.
  </li>
);

type SubscriptionPackage = {
  name: string;
};

type Props = {
  subscriptions: {
    subscriptionPackages: Array<SubscriptionPackage> | null;
    subscriptionPlans: Array<SubscriptionPackage> | null;
  };
};

export const PackageSelector = ({ subscriptions }: Props) => {
  const orgIndex = useSelector(selectCurrentOrganizationsIndex);
  let subscriptionPlans;
  let subscriptionPackages;
  if (subscriptions) {
    ({ subscriptionPackages, subscriptionPlans } = subscriptions);
  }

  let couponParam = '';
  let discount = 0;

  // create our secure-mail column
  let secureMail;
  const smPackage = subscriptionPackages?.find(
    (bundle) => bundle.name === 'SM',
  );

  if (smPackage) {
    secureMail = (
      <BundleRow
        title="Clinic Performance Package"
        description={<></>}
        rate={smPackage.basePrice / 100}
        discount={0}
        rateDescription="/ clinician per month"
        rateSubDescription=""
        upgradePath={`/o/${orgIndex}/settings/subscription/SM`}
      >
        <div>
          <div>
            Improve clinic effectiveness and increase staff, clinician, and
            patient satisfaction while you protect against privacy breaches.
            <br />
            <br />
            Clinics subscribe to the Clinic Performance Package to help staff be
            more productive, clinicians be more effective, operations run
            optimally, and patients be more engaged in their care.
            <br />
            <br />
            <ul>
              <li>Unlimited secure messaging</li>
              <li>500MB attachments</li>
              <li>1 Clinic Shared Inbox</li>
              <li>1 Clinician user account</li>
              <li>5 Staff user accounts</li>
              <li>Unlimited professional invites</li>
              <li>Unlimited patient invites</li>
              <li>Unlimited storage</li>
              <li>Bulk Messaging send up to 500 patients at once</li>
              <li>Privacy Breach Prevention training</li>
              <li>Clinic operations optimization support</li>
              {formsLi}
            </ul>
          </div>
          <br />
        </div>
      </BundleRow>
    );
  }

  // create our pia column
  let pia;
  const piaPackage = subscriptionPackages?.find(
    (bundle) => bundle.name === 'PACKAGE_PIA_INSTALLMENTS',
  );

  if (piaPackage) {
    pia = (
      <BundleRow
        title="Comprehensive Privacy Support Plan"
        description={<></>}
        rate={piaPackage.basePrice / 100}
        rateDescription="/ month"
        rateSubDescription="1-year commitment, Includes PIA"
        upgradePath={`/o/${orgIndex}/settings/subscription/PACKAGE_PIA_INSTALLMENTS${couponParam}`}
        discount={discount}
      >
        <div>
          Be prepared to prevent all breaches that would threaten public trust
          in your clinic.
          <br />
          <br />
          Our team of certified privacy compliance experts will examine every
          aspect of how you manage patient information to develop policies and
          procedures that establish reasonable measures to protect your clinic
          against privacy breaches.
          <br />
          <br />
          <ul>
            <li>
              Privacy Impact Assessment (PIA) prepared and submitted to the OIPC
              by our team of privacy experts
            </li>
            <li>All services included in the Essential Privacy Support Plan</li>
            <li>All services included in the Clinic Performance Plan</li>
          </ul>
        </div>
      </BundleRow>
    );
  }

  // create our privacy column
  let privacy;
  const privacyPackage = subscriptionPackages?.find(
    (bundle) => bundle.name === 'PACKAGE_PRIVACY_SERVICES',
  );

  if (privacyPackage) {
    privacy = (
      <BundleRow
        title="Essential Privacy Support Plan"
        description={<></>}
        rate={privacyPackage.basePrice / 100}
        discount={0}
        rateDescription="/ month"
        rateSubDescription="1-year commitment"
        upgradePath={`/o/${orgIndex}/settings/subscription/PACKAGE_PRIVACY_SERVICES`}
      >
        <div>
          The risk of privacy breach grows every day. To protect your reputation
          with patients and colleagues, you must get breach prevention right
          every time.
          <br />
          <br />
          We will establish your active privacy management system with on-call
          Privacy Officer support, as well as privacy compliance, cyber
          awareness, and breach prevention training.
          <br />
          <br />
          <ul>
            <li>Privacy Breach Prevention Checklist</li>
            <li>Online privacy training for all staff and clinicians</li>
            <li>On-call privacy compliance support</li>
            <li>Privacy Officer tools</li>
            <li>PIA amendments</li>
            <li>Agreement support</li>
            <li>Vendor selection guidance</li>
            <li>Privacy breach investigation and reporting guidance</li>
            <li>Annual Privacy Officer conference attendance</li>
            <li>All services included in the Clinic Performance Plan</li>
          </ul>
        </div>
      </BundleRow>
    );
  }

  // create our pia standalone column
  let piaStandalone;
  const piaStandalonePackage = subscriptionPlans?.find(
    (bundle) => bundle.name === 'PIA_DENTAL' || bundle.name === 'PIA_MEDICAL',
  );

  if (piaStandalonePackage) {
    piaStandalone = (
      <BundleRow
        title="Privacy Impact Assessment (PIA)"
        description={<></>}
        rate={piaStandalonePackage.amount / 100}
        discount={discount}
        rateDescription="/ month"
        rateSubDescription=""
        upgradePath={`/o/${orgIndex}/settings/subscription/${piaStandalonePackage.name}${couponParam}`}
      >
        <div>
          Be prepared to prevent all breaches that would threaten patient trust
          in your clinic. Our team of certified privacy compliance experts will
          examine every aspect of how you manage patient information to develop
          policies and procedures that establish reasonable measures to protect
          your clinic against privacy breaches.
          <br />
          <br />
          <ul>
            <li>Privacy Impact Assessment</li>
            <li>
              Unlimited online privacy training for all staff and clinicians
            </li>
            <li>On-call privacy compliance support (incl. PIA amendments)</li>
            <li>Privacy breach investigation and reporting guidance</li>
            <li>
              Secure-Mail for 1 clinician (extra clinicians are $57.49/month)
            </li>
          </ul>
        </div>
      </BundleRow>
    );
  }

  return (
    <React.Fragment>
      <h4 className="text-center">
        {subscriptionPackages && <span>Choose your subscription</span>}
        {subscriptionPlans && <span>Choose your add-on</span>}
      </h4>
      <div className="container-fluid">
        <div className="packages column equal">
          {secureMail}
          {privacy}
          {pia}
          {piaStandalone}
        </div>
      </div>
    </React.Fragment>
  );
};

export default PackageSelector;
