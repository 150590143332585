import { faEye, faEyeSlash } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { OverlayTrigger, Popover, Tooltip } from 'react-bootstrap';

export const Password = ({
  name,
  value,
  onChange,
  placeholder,
  className,
  popoverString,
}: {
  name: string;
  value: string;
  onChange: (_e: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  className?: string;
  popoverString?: string;
}) => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <div className="input-group f-input-group password-group">
      <input
        required
        type={showPassword ? 'text' : 'password'}
        className={className}
        name={name}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
      />
      <OverlayTrigger
        trigger={['hover', 'focus']}
        placement="right"
        overlay={
          <Popover id="password">
            {showPassword ? 'Hide' : 'Show'} {popoverString ?? 'password'}
          </Popover>
        }
      >
        <div className="input-group-addon">
          <a
            tabIndex={-1}
            href="#"
            onClick={(e) => {
              e.preventDefault();
              setShowPassword(!showPassword);
            }}
          >
            <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
          </a>
        </div>
      </OverlayTrigger>
    </div>
  );
};
